import defaultSettings from '@/settings'
import {useDynamicTitle} from '@/utils/dynamicTitle'

const {sysThemeIsDark , sideTheme, showSettings, topNav, tagsView, fixedHeader, sidebarLogo, dynamicTitle} = defaultSettings

const storageSetting = JSON.parse(localStorage.getItem('layout-setting')) || ''

const useSettingsStore = defineStore('settings', {
    state: () => ({
        title: '',
        theme: storageSetting.theme || '#409EFF',
        sysThemeIsDark: storageSetting.sysThemeIsDark || sysThemeIsDark,
        sideTheme: storageSetting.sideTheme || sideTheme,
        showSettings: showSettings,
        topNav: storageSetting.topNav === undefined ? topNav : storageSetting.topNav,
        tagsView: storageSetting.tagsView === undefined ? tagsView : storageSetting.tagsView,
        fixedHeader: storageSetting.fixedHeader === undefined ? fixedHeader : storageSetting.fixedHeader,
        sidebarLogo: storageSetting.sidebarLogo === undefined ? sidebarLogo : storageSetting.sidebarLogo,
        dynamicTitle: storageSetting.dynamicTitle === undefined ? dynamicTitle : storageSetting.dynamicTitle
    }),
    actions: {
        // 修改布局设置
        changeSetting(data) {
            const {key, value} = data
            if (this.hasOwnProperty(key)) {
                this[key] = value
            }
        }, // 设置网页标题
        setTitle(title) {
            this.title = title
            useDynamicTitle();
        },
        setSysThemeIsDark(sysThemeIsDark) {
            this.sysThemeIsDark = sysThemeIsDark
        },
        getSysThemeIsDark(sysThemeIsDark) {
            let dom = document.querySelector('html')
            if (sysThemeIsDark) {
                // console.log('用户喜好的主题模式为暗色模式');
                if (dom.classList.contains('light'))
                    dom.classList.remove('light')
                dom.classList.add('dark')
            } else {
                // console.log('用户喜好的主题模式为亮色模式');
                if (dom.classList.contains('dark'))
                    dom.classList.remove('dark')
                dom.classList.add('light')
            }
        },
    }
})

export default useSettingsStore
